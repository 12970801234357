import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import BASE_URL from './Webserver';

const Gallery = () => {
  const [images, setImages] = useState([]); // State for storing image data
  const [loading, setLoading] = useState(true); // State for loading animation
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // State for selected image in modal
  const [currentPage, setCurrentPage] = useState(1); // State for current page in pagination
  const [currentSlide, setCurrentSlide] = useState(0); // State for current slide index in slideshow
  const imagesPerPage = 8; // Number of images per page
  const slideshowInterval = 3000; // Interval for automatic slideshow (3 seconds)
  const slideshowTimerRef = useRef(null); // Ref for slideshow timer

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/photos/`);
        setImages(response.data); // Update state with fetched images
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching images:', error);
        setLoading(false); // Ensure loading is stopped even if there's an error
      }
    };

    fetchImages();

    // Automatic slideshow interval
    slideshowTimerRef.current = setInterval(() => {
      setCurrentSlide((currentSlide) => (currentSlide + 1) % images.length);
    }, slideshowInterval);

    return () => {
      clearInterval(slideshowTimerRef.current); // Clean up interval on component unmount
    };
  }, [images.length]); // Dependency array includes images.length to restart the interval on images change

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedImageIndex(null); // Close enlarged image when changing pages
  };

  const goToNextPage = () => {
    if (currentPage < Math.ceil(images.length / imagesPerPage)) {
      setCurrentPage(currentPage + 1);
      setSelectedImageIndex(null); // Close enlarged image when moving to next page
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setSelectedImageIndex(null); // Close enlarged image when moving to previous page
    }
  };

  const goToNextImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex < images.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const goToPreviousImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const goToNextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };

  const handleModalClick = (e) => {
    // Close modal if clicked outside of the image
    if (e.target.classList.contains('modal-overlay')) {
      closeImage();
    }
  };

  const smoothTransitionStyle = {
    transition: 'opacity 0.8s ease-in-out' // Smooth opacity transition
  };

  // Render the current image in the slideshow
  const currentSlideshowImage = images[currentSlide]?.file;
  const currentSlideshowCaption = images[currentSlide]?.caption;

  // Calculate current images based on pagination
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  return (
    <div id="gallery" className="container mx-auto py-12">
      <h1 className="mt-10 text-2xl font-bold text-center "></h1>

      {/* Central Slideshow */}
      <div className="relative mt-4 mx-auto max-w-screen-lg mb-4">
  <button className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white text-2xl z-10 bg-black bg-opacity-50 p-2 rounded-full cursor-pointer" onClick={goToPreviousSlide}>&lt;</button>
  {currentSlideshowImage && (
    <div className="text-center">
      <img src={currentSlideshowImage} alt={`Slideshow Image ${currentSlide}`} className="mx-auto max-h-60 object-contain rounded-lg shadow-md cursor-pointer" onClick={() => openImage(currentSlide)} />
      {currentSlideshowCaption && (
        <p className="bg-black bg-opacity-50 text-white text-sm p-2 rounded-lg mx-auto mt-2 max-w-[40%]">
          {currentSlideshowCaption}
        </p>
      )}
    </div>
  )}
  <button className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white text-2xl z-10 bg-black bg-opacity-50 p-2 rounded-full cursor-pointer" onClick={goToNextSlide}>&gt;</button>
</div>


      {/* Gallery Grid */}
      {/* Gallery Grid Container */}
<div className="flex justify-center">
  {/* Gallery Grid */}
  <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 ${loading ? 'animate-fadeIn' : ''}`} style={smoothTransitionStyle}>
    {currentImages.map((image, index) => (
      <div key={indexOfFirstImage + index} className="group relative" onClick={() => openImage(indexOfFirstImage + index)}>
        <img src={image.file} alt={`Gallery Image ${indexOfFirstImage + index}`} className="w-40 h-auto rounded-lg shadow-md cursor-pointer" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button className="bg-gray-800 text-white rounded-full px-3 py-1 text-sm">View</button>
        </div>
        {image.caption && (
          <div className="absolute bottom-0 left-4 bg-black bg-opacity-50 text-white text-sm p-1 rounded-b-lg">
            {image.caption}
          </div>
        )}
      </div>
    ))}
  </div>
</div>

      {/* Pagination */}
      <div className="flex justify-center mt-8">
        <button
          onClick={goToPreviousPage}
          className={`mx-1 px-3 py-1 rounded-full text-sm focus:outline-none ${currentPage === 1 ? 'bg-gray-200 text-gray-800 cursor-not-allowed' : 'bg-gray-800 text-white'}`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: Math.ceil(images.length / imagesPerPage) }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`mx-1 px-3 py-1 rounded-full text-sm focus:outline-none ${currentPage === index + 1 ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={goToNextPage}
          className={`mx-1 px-3 py-1 rounded-full text-sm focus:outline-none ${currentPage === Math.ceil(images.length / imagesPerPage) ? 'bg-gray-200 text-gray-800 cursor-not-allowed' : 'bg-gray-800 text-white'}`}
          disabled={currentPage === Math.ceil(images.length / imagesPerPage)}
        >
          Next
        </button>
      </div>

      {/* Modal for displaying selected image */}
      {selectedImageIndex !== null && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 modal-overlay" onClick={handleModalClick}>
          <div className="max-w-screen-lg w-full p-4">
            <div className="relative">
              <button className="absolute top-4 left-4 text-white text-2xl" onClick={goToPreviousImage}>&lt;</button>
              <button className="absolute top-4 right-4 text-white text-2xl" onClick={goToNextImage}>&gt;</button>
              <button className="absolute top-4 right-12 text-white text-2xl" onClick={closeImage}>&times;</button>
              {images[selectedImageIndex] && (
                <div className="text-center">
                  <img src={images[selectedImageIndex].file} alt={`Gallery Image ${selectedImageIndex}`} className="mx-auto max-h-screen" />
                  {images[selectedImageIndex].caption && (
                    <p className="mt-2 text-white">{images[selectedImageIndex].caption}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
