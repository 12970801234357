/* eslint-disable jsx-a11y/no-redundant-roles */
import Map from '../Static/Map.jpg'

export default function Example() {
  return (
    <div id="about" className="relative isolate overflow-hidden bg-white px-0 py-2 sm:py-2 lg:px-2">
      <div className="mx-auto max-w-7xl">
        {/* Content Container */}
        <div className="space-y-0 lg:space-y-0">
          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900">Rainadevi Chhahara Kathmandu Friendship Society</h3>
          <p className="text-justify text-xs px-4 py-1 leading-relaxed">
            Rainadevi Chhahara Kathmandu Friendship Society is an organized, self-governing, non-political, non-profit, non-governmental, public-interest social organization. It was registered with the District Administration Office, Kathmandu on 10th October 2023 as per the clause (4) of the Act of 2034, with registration certificate number 69/2080/081. Additionally, it received a registration certificate number 55878 from the Social Welfare Council, Lalitpur on 17th September 2023 under clause 13 of the Social Welfare Act, 2049.<br />
            The office of this organization, which operates within the Rainadevi Chhahra Municipality and Kathmandu Valley, is currently located in Chapali, Ward No. 2, Budhanilkantha Municipality, Kathmandu District.
          </p>
          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900 ">Membership Regulations</h3>
          <p className="text-justify text-xs px-4 py-1 leading-relaxed">
            According to clause 6 of section 3 of the Rainadevi Chhahra Milan Society Kathmandu’s constitution 2080, there are four types of members:
            <span className='block pl-6'>
              <strong> (a) General Member</strong> <br />
              A person who fulfills the eligibility criteria specified in clause 7 of the constitution and completes the designated process can become an ordinary member of the organization. <br />
              <strong> (b) Founder Member</strong><br />
              Individuals who obtained membership at the time of the organization’s registration will be founding members. Founding members must either take ordinary or lifetime membership and pay the fees as prescribed by the constitution. <br />
              <strong> (c) Lifetime Member</strong><br />
              A person who fulfills the eligibility criteria specified in clause 7 of the constitution and completes the designated process can be granted lifetime membership upon payment of the prescribed fee. <br />
              <strong> (d) Honorary Member</strong><br />
              The organization can grant honorary membership to philanthropists and esteemed individuals who have made significant contributions to the organization’s development, as decided by the general assembly.
            </span>
          </p>

          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900 ">Objective</h3>
          <p className="text-justify text-xs px-4 leading-relaxed">
            To contribute to sustainable community and economic development, environmental balance, and preservation of local culture.
          </p>

          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900 ">Goals</h3>
          <p className="text-justify text-xs px-4 leading-relaxed">
            The organization has the following goals under the constitution, subject to prevailing laws:
            <ul className="list-disc pl-6">
              <li className="ml-4">
                To explore, study, and investigate religious and cultural heritage sites, fortresses, temples, gompas, etc., within the Rainadevi Chhahra Municipality.
              </li>
              <li className="ml-4">
                To conduct research and studies in education, health, agriculture, forest products, tourism, etc., for the prosperity of citizens and provide recommendations and suggestions to local, provincial, and federal governments.
              </li>
              <li className="ml-4">
                To conduct research and studies in education, health, agriculture, forest products, tourism, etc., for the prosperity of citizens and provide recommendations and suggestions to local, provincial, and federal governments.
              </li>
              <li className="ml-4">
                To honor and encourage senior citizens, distinguished scholars, students, and professionals in the field of work.
              </li>
              <li className="ml-4">
                To enhance interpersonal relations and foster social harmony and equality by strengthening relationships among people in the working area.
              </li>
              <li className="ml-4">
                To maintain respect and cordial relations with other non-governmental organizations in the working area and coordinate and collaborate on their creative and constructive activities.
              </li>
            </ul>
          </p>

          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900">Strategies</h3>
          <p className="text-justify text-xs px-4 leading-relaxed">
            To achieve the objectives, the following strategies will be adopted:
          </p>
          <ul className="text-justify text-xs mb-4 leading-relaxed list-disc pl-6">
            <li className="ml-8">
              Participatory political process with the support and cooperation of local people for prosperity and development.
            </li>
            <li className="ml-8">
              Prioritization and encouragement for sustainable development.
            </li>
            <li className="ml-8">
              Optimal use of human capital.
            </li>
            <li className="ml-8">
              Optimal use of natural resources.
            </li>
            <li className="ml-8">
              Enhancing capacity to withstand natural disasters, risk reduction, and climate change adaptation.
            </li>
            <li className="ml-8">
              Coordination in the contribution of migrant labor.
            </li>
          </ul>

          <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900">Main Activities</h3>
          <p className="text-justify text-xs px-4 leading-relaxed">
            The organization will prioritize the following activities:
            <ul role="list" className="list-disc pl-6 mt-0">
              <li className="ml-4 text-justify text-xs leading-relaxed">
                To enhance and strengthen interpersonal relationships among members, promoting brotherhood, unity, social harmony, and the culture of equality.
              </li>
              <li className="ml-4 text-justify text-xs leading-relaxed">
                To study and explore historical, archaeological sites, fortresses, temples, and other heritage sites within the municipality and provide strategic advice, recommendations, and suggestions to concerned authorities.
              </li>
              <li className="ml-4 text-justify text-xs leading-relaxed">
                To contribute to economic, social transformation, and the protection and promotion of cultural identity within the municipality.
              </li>
              <li className="ml-4 text-justify text-xs leading-relaxed">
                To conduct programs as per the requirements for achieving organizational objectives after obtaining the necessary permits or approvals as per Nepalese law.
              </li>
            </ul>
          </p>

          <h3 className="ml-[10px] text-red-900 text-sm pt-2 font-bold tracking-tight text-gray-900">&nbsp;Brief Introduction of Rainadevi Chhahra Municipality</h3>
          <p className="text-justify pt-1 text-xs px-4 leading-relaxed">
            Rainadevi Chhahra Municipality is located in the west of Palpa District in Lumbini Province. It includes the former eight village development committees—Siddheshwar, Somadi, Bhuvanpokhari, Chhahra, Mujung, Juthapauwa, Baldengadhi, and Satyawati. The total area of this municipality is 175.88 square kilometers, with a population of 26,469 (National Census, 2021).<br />
            The four borders of the municipality are as follows:
            <span className="block ml-8">
              East: Ribdikot and Tinau Rural Municipality.<br />
              West: Arghakhanchi District<br />
              North: Gulmi District<br />
              South: Rupandehi District
            </span>
            <div className="flex justify-center">
              <img
                className="w-full max-w-[40rem] rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                src={Map}
                alt="Geographical Map of Rainadevi Chhahra Municipality"
              />
            </div>
            <br />
            <center className="ml-[10px] text-red-900 text-1xl font-bold tracking-tight text-gray-900">Geographical Map of Rainadevi Chhahra Municipality</center>
          </p>
        </div>
      </div>
    </div>
  )
}
