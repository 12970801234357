import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import BASE_URL from './Webserver';

const ReportsPublicationsPage = () => {
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState('report'); // Toggle between 'report' and 'publication'
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Adjust the number of items per page as needed

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/reports-publications/`);
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  const filteredItems = items
    .filter(item => item.category === category)
    .filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase()));

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const paginatedItems = filteredItems.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div className="container mx-auto px-12 py-24">
      {/* Toggle Switch */}
      <div className="flex justify-center mb-2 space-x-2">
        <button
          className={`px-4 py-2 rounded-l-lg text-xs font-medium ${category === 'report' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
          onClick={() => setCategory('report')}
        >
          Reports
        </button>
        <button
          className={`px-4 py-2 rounded-r-lg text-xs font-medium ${category === 'publication' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
          onClick={() => setCategory('publication')}
        >
          Publications
        </button>
      </div>

      {/* Search Bar */}
      <div className="mb-2 max-w-xs mx-auto">
        <input
          type="text"
          placeholder="Search..."
          className="w-full px-4 py-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Display Items */}
      <div className="space-y-1">
        {paginatedItems.map((item) => (
          <div key={item.id} className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow duration-200">
            <div className="font-semibold text-xs text-gray-800">{item.title}</div>
            <a
              href={item.file}
              className="text-blue-600 text-xs hover:underline mt-1 inline-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download File
            </a>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="mt-2 flex justify-center">
        <ReactPaginate
          previousLabel={"< Previous"}
          nextLabel={"Next >"}
          pageCount={Math.ceil(filteredItems.length / itemsPerPage)}
          onPageChange={handlePageClick}
          containerClassName={"flex space-x-2"}
          pageClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
          activeClassName={"bg-blue-600 text-white"}
          pageLinkClassName={"text-gray-700"}
          previousLinkClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
          nextLinkClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
        />
      </div>
    </div>
  );
};

export default ReportsPublicationsPage;
