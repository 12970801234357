import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BASE_URL from './Webserver';

const Departments = () => {
  const [departmentMembers, setDepartmentMembers] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    
    fetch(`${BASE_URL}api/members/`)
      .then(response => response.json())
      .then(data => {
        // Filter and organize members with 'department' category position
        const filteredMembers = data
          .filter(member => member.category_position === 'department')
          .sort((a, b) => a.membership_number - b.membership_number); // Optional: Sort by membership number

        // Update state with filtered members
        setDepartmentMembers(filteredMembers);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='mb-[2%]' >
      <div className="text-center  mt-[30%] sm:mt-[13%]  sm:py-1 ">
        <button
              className="px-4 py-2 rounded-l-lg  mb-[1%] text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/members'}
            >
                 View Executive Team
            </button>
        
            <button
              className="px-4 py-2 rounded-r-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/advisors'}
            >
             View Advisors Team
            </button>
            <button
              className="px-4 py-2 rounded-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/lifemembers'}
            > View All Members
            </button>



      </div>

      <div className="max-w-4xl mx-auto">
        <h2 className="text-center text-red-900 text-lg font-bold">विभागीय संयोजक</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2">
          {departmentMembers.map((member, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-1 flex items-center space-x-2">
              <img className="h-20 w-20 rounded-full border-2 border-gray-300" src={member.photo} alt={member.title} />
              <div>
                <h3 className="text-md font-semibold text-gray-900">{member.department || 'Department Name'} विभाग</h3>
                <p className="text-sm text-indigo-600">संयोजक </p>

                <p className="text-sm text-indigo-600">  {`${member.first_name} ${member.middle_name ? member.middle_name + ' ' : ''}${member.last_name}`}</p>
             
                <p className="text-sm text-gray-600">{member.phone_number}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Departments;
