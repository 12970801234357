import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from './Webserver';

const EventsCalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [notices, setNotices] = useState([]);
  const [date, setDate] = useState(new Date());
  const [hoveredDay, setHoveredDay] = useState(null);
  const [expandedNoticeId, setExpandedNoticeId] = useState(null);
  const [modalNotice, setModalNotice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEventsPage, setCurrentEventsPage] = useState(1);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const noticesPerPage = 2;
  const eventsPerPage = 3;
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    setToday(new Date());
  }, []);

  const isToday = (day) => {
    return day === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/events/`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    const fetchNotices = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/notice-bulletins/`);
        const sortedNotices = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNotices(sortedNotices);
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };

    fetchEvents();
    fetchNotices();
  }, []);

  useEffect(() => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

    const filteredEvents = events.filter(event => {
      const startDate = new Date(event.start_date);
      const endDate = event.end_date ? new Date(event.end_date) : startDate;
      return startDate <= endOfMonth && endDate >= startOfMonth;
    });

    setUpcomingEvents(filteredEvents);
  }, [date, events]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const isDateHighlighted = (day) => {
    const checkDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), day));
    return events.some(event => {
      const startDate = new Date(event.start_date);
      const endDate = event.end_date ? new Date(event.end_date) : startDate;
      return startDate <= checkDate && checkDate <= endDate;
    });
  };

  const getEventDescription = (day) => {
    const checkDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), day));
    const event = events.find(event => {
      const startDate = new Date(event.start_date);
      const endDate = event.end_date ? new Date(event.end_date) : startDate;
      return startDate <= checkDate && checkDate <= endDate;
    });

    if (event) {
      const startDate = new Date(event.start_date).toLocaleDateString();
      const endDate = event.end_date ? new Date(event.end_date).toLocaleDateString() : startDate;
      return startDate !== endDate
        ? `From ${startDate} to ${endDate}: ${event.name}`
        : `On ${startDate}: ${event.name}`;
    }
    return '';
  };

  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const daysInMonth = endOfMonth.getDate();
  const startDay = startOfMonth.getDay();

  const calendarDays = [
    ...Array(startDay).fill(null),
    ...Array.from({ length: daysInMonth }, (_, index) => index + 1)
  ];

  const totalSlots = Math.ceil(calendarDays.length / 7) * 7;
  const emptySlots = totalSlots - calendarDays.length;
  const calendarDaysWithPadding = [...calendarDays, ...Array(emptySlots).fill(null)];

  const indexOfLastEvent = currentEventsPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentUpcomingEvents = upcomingEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const handleEventsPageChange = (pageNumber) => {
    setCurrentEventsPage(pageNumber);
  };

  const indexOfLastNotice = currentPage * noticesPerPage;
  const indexOfFirstNotice = indexOfLastNotice - noticesPerPage;
  const currentNotices = notices.slice(indexOfFirstNotice, indexOfLastNotice);

  const handleToggleDetails = (id) => {
    setExpandedNoticeId(expandedNoticeId === id ? null : id);
  };

  const handleOpenModal = (notice) => {
    setModalNotice(notice);
  };

  const handleCloseModal = () => {
    setModalNotice(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto px-4 py-20">
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-6">
        {/* Calendar Section (1/4 width) */}
        <div className="bg-white rounded-lg shadow-sm p-4 col-span-2 h-80 relative overflow-visible sm:col-span-2">
          <h2 className="text-sm text-red-900 font-bold mb-2">Calendar</h2>
          {/* Month Navigation */}
          <div className="flex items-center justify-between mb-1">
            <button
              className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
              onClick={() => handleDateChange(new Date(date.getFullYear(), date.getMonth() - 1))}
            >
              Previous
            </button>
            <div className="text-xs font-semibold">
              {date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}
            </div>
            <button
              className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
              onClick={() => handleDateChange(new Date(date.getFullYear(), date.getMonth() + 1))}
            >
              Next
            </button>
          </div>
          {/* Calendar Days */}
          <div className="grid grid-cols-7 gap-1 mb-0 text-center text-xs font-semibold text-gray-500">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
              <div key={day}>{day}</div>
            ))}
            {calendarDaysWithPadding.map((day, index) => (
              <div
                key={index}
                className={`relative text-center p-2 rounded-sm ${day ? (isDateHighlighted(day) ? 'bg-blue-200' : '') : ''} ${day && isToday(day) ? 'border-2 border-red-500' : ''}`}
                onMouseEnter={() => setHoveredDay(day)}
                onMouseLeave={() => setHoveredDay(null)}
              >
                {day || ''}
                {hoveredDay === day && day && isDateHighlighted(day) && (
                  <div className="absolute bg-gray-800 text-white text-xs p-2 rounded mt-1 left-1/2 transform -translate-x-1/2 z-10">
                    {getEventDescription(day)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Upcoming Events Section (1/4 width) */}
        <div className="rounded-lg shadow-sm p-4 col-span-2">
          <h1 className="text-sm text-red-900 font-bold mb-0">&nbsp; &nbsp; Upcoming Events</h1>
          <div className="space-y-2">
            {upcomingEvents.length === 0 ? (
              <div className="text-center text-gray-600 text-xs">No events scheduled for this month.</div>
            ) : (
              currentUpcomingEvents.map((event) => {
                const startDate = new Date(event.start_date).toLocaleDateString();
                const endDate = event.end_date ? new Date(event.end_date).toLocaleDateString() : startDate;
                return (
                  <div key={event.id} className="bg-gray-300 mt-1 p-2 rounded-sm shadow-sm">
                    <div className="font-semibold text-xs text-gray-800">{event.name}</div>
                    <div className="text-xs text-gray-600" style={{ fontSize: '0.6rem' }}>
                      {startDate !== endDate ? `From ${startDate} to ${endDate}` : `On ${startDate}`}
                    </div>
                    <div className="text-xs text-gray-600">{event.title}</div>
                    <p className="text-gray-700 mt-1 text-xs">{event.information}</p>
                  </div>
                );
              })
            )}
          </div>
          {upcomingEvents.length > 0 && (
            <div className="flex justify-between mt-2">
              <button
                className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
                onClick={() => handleEventsPageChange(currentEventsPage - 1)}
                disabled={currentEventsPage === 1}
              >
                Previous
              </button>
              <span className="text-xs">
                Page {currentEventsPage} of {Math.ceil(upcomingEvents.length / eventsPerPage)}
              </span>
              <button
                className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
                onClick={() => handleEventsPageChange(currentEventsPage + 1)}
                disabled={currentEventsPage === Math.ceil(upcomingEvents.length / eventsPerPage)}
              >
                Next
              </button>
            </div>
          )}
        </div>

        {/* Notices Section (2/4 width) */}
        <div className="bg-white rounded-lg shadow-sm p-4 col-span-2 h-80 overflow-auto">
          <h2 className="text-sm text-red-900 font-bold mb-1">Notice Bulletin</h2>
          <div className="space-y-2">
            {currentNotices.map((notice) => (
              <div key={notice.id} className="bg-gray-200 p-4 rounded-sm shadow-sm">
                <div
                  className="font-semibold text-center text-xs text-gray-800 cursor-pointer"
                  style={{ fontSize: '0.7rem' }}
                  onClick={() => handleOpenModal(notice)}
                >
                  {notice.title}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-2">
            <button
              className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-xs">
              Page {currentPage} of {Math.ceil(notices.length / noticesPerPage)}
            </span>
            <button
              className="bg-gray-200 hover:bg-gray-300 px-2 py-1 rounded-sm text-xs"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(notices.length / noticesPerPage)}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Modal for displaying selected notice */}
      {modalNotice && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 modal-overlay" onClick={handleCloseModal}>
          <div className="bg-white p-12 rounded-lg max-w-2xl w-full relative">

            <button className="absolute top-4 right-4 text-gray-800 text-2xl" onClick={handleCloseModal}>&times;</button>
            <h2 className="text-xs font-semibold text-red-900 text-center mb-2">{modalNotice.title}</h2>
            <div className="text-xs text-gray-600 mb-2" style={{ fontSize: '0.6rem', textAlign: 'justify', whiteSpace: 'pre-wrap' }}>{modalNotice.date}</div>
            <p className="text-gray-700 mt-1 text-xs text-center" style={{ fontSize: '0.6rem', textAlign: 'justify', whiteSpace: 'pre-wrap' }}>{modalNotice.details}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsCalendarPage;
