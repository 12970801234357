import React, { useState } from 'react';
import axios from 'axios';
import Application from './Application'; // Import the Application component
import BASE_URL from './Webserver';

export default function Example() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const openFacebookGroup = () => {
    window.open('https://www.facebook.com/groups/1129277277910557', '_blank');
  };

  const openApplicationModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeApplicationModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}api/feedback/`, formData);
      setIsSubmitted(true);
      setSuccessMessage('Feedback submitted successfully!');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to submit feedback. Please try again.');
      setSuccessMessage('');
      
    }
  };

  const handleBackdropClick = (e) => {
    // Close the modal only if the click is on the backdrop
    if (e.target === e.currentTarget) {
      closeApplicationModal();
    }
  };

  return (
    <div id="contact" className="bg-white px-6 lg:px-8">
      <div className="mx-auto max-w-4xl mt-[30%] flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0 sm:space-x-6 sm:mt-[15%]">
        {/* Feedback Form Section */}
        {!isSubmitted ? (
          <div className="w-full sm:w-1/2 mt-2 p-4 border rounded-md shadow-lg">
            <h2 className="text-sm font-semibold text-gray-900">Contact Us</h2>
            <p className="text-xs text-gray-600">
              Email: rcms.kathmandu@gmail.com <br />Phone:
              President: 9851010602
              Secretary: 9851181110
            </p>

            <h2 className="text-sm font-semibold text-gray-900">Leave your Feedback</h2>
            <p className="text-xs text-gray-600 mb-0">
              Please fill out the form below and give us your valuable feedback.
            </p>

            <form onSubmit={handleSubmit} className="space-y-1">
              <div className="grid grid-cols-1 gap-y-1 sm:grid-cols-2 sm:gap-x-2">
                <div>
                  <label htmlFor="first-name" className="block text-xs font-medium text-gray-900">
                    First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    id="first-name"
                    autoComplete="given-name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className="block w-full rounded-md border border-gray-300 px-2 py-1 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-xs font-medium text-gray-900">
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    id="last-name"
                    autoComplete="family-name"
                    value={formData.last_name}
                    onChange={handleChange}
                    className="mt-0 block w-full rounded-md border border-gray-300 px-2 py-1 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-2 grid grid-cols-1 gap-y-1 sm:grid-cols-2 sm:gap-x-2">
                  <div>
                    <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="mt-0 block w-full rounded-md border border-gray-300 px-2 py-1 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone-number" className="block text-xs font-medium text-gray-900">
                      Phone number
                    </label>
                    <input
                      type="tel"
                      name="phone_number"
                      id="phone-number"
                      autoComplete="tel"
                      value={formData.phone_number}
                      onChange={handleChange}
                      className="mt-0 block w-full rounded-md border border-gray-300 px-2 py-1 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-xs font-medium text-gray-900">
                   Feedback
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows={2}
                    value={formData.message}
                    onChange={handleChange}
                    className="mt-0 block w-full rounded-md border border-gray-300 px-2 py-1 text-gray-900 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-10% rounded-md bg-indigo-600 px-4 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-indigo-600"
              >
                Submit
              </button>
            </form>

            {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
            {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
          </div>
        ) : (
          <div className="w-full sm:w-1/2 text-center">
            <h2 className="text-lg font-semibold text-gray-900">Contact Us</h2>
            <p className="text-sm text-gray-600">
              Email: rcms.kathmandu@gmail.com <br />Phone:
              President: 9851010602
              Secretary: 9851181110
            </p>
            <h2 className="text-xl font-semibold text-gray-900 mt-4">Thank you for your feedback!</h2>
            <p className="text-gray-600 mt-2">We appreciate you taking the time to share your thoughts with us.</p>
          </div>
        )}

        {/* Visit Us on Facebook Section */}
        <div className="w-full sm:w-1/2 flex flex-col items-center ">
          <p className="mb-0 text-center">Membership Application</p> <br />
          <p className="mb-2 text-center">If you want to be a member of our society, please click on the link below to fill out the application form:</p> <br />
          <button
            onClick={openApplicationModal}
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md text-sm"
          >
            Application Form
          </button>
          <hr /> <br />
        </div>
      </div>

      {/* Custom Modal for Application Form */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleBackdropClick}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl relative"
            onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
          >
            <button
              onClick={closeApplicationModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              &times;
            </button>
            <Application /> {/* This is your application form component */}
          </div>
        </div>
      )}
    </div>
  );
}
