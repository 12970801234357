import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Component/Nepali/Header';
import Landing from './Component/Nepali/Landing';
import Members from './Component/Nepali/Members';
import Gallery from './Component/Nepali/Gallery';
import Contact from './Component/Nepali/Contact';
import Departments from './Component/Nepali/Departments';
import LifeMembers from './Component/Nepali/LifeMembers';
import Advisors from './Component/Nepali/Advisors';
import Events from './Component/Nepali/Events';
import Application from './Component/Nepali/Application';
import Reports from './Component/Nepali/Reports';
import './App.css';
import DownloadSitemap from './sitemap';

function App() {
  return (
    <Router>
      <div>
      <Header /> {/* Include Header here */}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/sitemap" element={<DownloadSitemap />} />
          <Route path="/members" element={<Members />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/advisors" element={<Advisors />} />
          <Route path="/lifeMembers" element={<LifeMembers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/events" element={<Events />} />
          <Route path="/application" element={<Application />} />
          <Route path="/reportPublication" element={<Reports />} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
