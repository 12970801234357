import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from './Webserver';

export default function MembershipForm() {
  const [formData, setFormData] = useState({
    full_name: '',
    gender: '',
    origin_address: '',
    existing_address: '',
    phone_number: '',
    occupation: '',
    email: '',
    membership_type: '',
    amount: '',
    voucher_photo: '',
    applicant_photo: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({
        ...formData,
        [name]: files[0] // For handling file inputs
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post(`${BASE_URL}api/applications/`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setIsSubmitted(true);
      setSuccessMessage('Membership application submitted successfully!');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to submit membership application. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
     
    <div className="p-1 bg-gray-200 z-99 max-w-4xl max-h-[80vh] overflow-y-auto mx-auto sm:h-[75vh]">
      {!isSubmitted ? (
        <div className=" overflow-auto"> {/* Set height and enable scroll */}
          <h2 className="text-lg text-center text-red-900  font-semibold text-gray-900 mb-4">Membership Application Form</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-1">
              <div>
                <label htmlFor="name" className="block text-xs font-medium text-gray-900">नाम, थर:</label>
                <input
                  type="text"
                  id="name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                  required
                />
              </div>
              <div>
                <label htmlFor="gender" className="block text-xs font-medium text-gray-900">लिँग:</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                  required
                >
                  <option value="" disabled>Choose Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div>
                <label htmlFor="contactNumber" className="block text-xs font-medium text-gray-900">सम्पर्क नम्बरः</label>
                <input
                  type="tel"
                  id="contactNumber"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="currentAddress" className="block text-xs font-medium text-gray-900">हालको ठेगानाः</label>
                <input
                  type="text"
                  id="currentAddress"
                  name="existing_address"
                  value={formData.existing_address}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="previousAddress" className="block text-xs font-medium text-gray-900">साबिक ठेगानाः</label>
                <input
                  type="text"
                  id="previousAddress"
                  name="origin_address"
                  value={formData.origin_address}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="profession" className="block text-xs font-medium text-gray-900">पेशा / ब्यबसाय / संग्लन संस्था:</label>
                <input
                  type="text"
                  id="profession"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-xs font-medium text-gray-900">ई-मेल:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="membershipType" className="block text-xs font-medium text-gray-900">सदस्यताको किसिमः</label>
                <select
                  id="membershipType"
                  name="membership_type"
                  value={formData.membership_type}
                  onChange={handleChange}
                  className="mt-1 block w-20% rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                >
                  <option value="" disabled>Choose Membership Type</option>
                  <option value="lifetime">Lifetime</option>
                  <option value="general">General</option>
                </select>
              </div>
              <div>
                <label htmlFor="membershipFee" className="block text-xs font-medium text-gray-900">सदस्यता शुल्क बापत बैँकमा जम्मा गरेको रकम</label>
                <input
                  type="text"
                  id="membershipFee"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  className="mt-1 block w-20% rounded-md border border-gray-300 px-1 py-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="feeProof" className="block text-xs font-medium text-gray-900">सदस्यता शुल्क बैँक खातामा जम्मा गरेको प्रमाण</label>
                <input
                  type="file"
                  id="feeProof"
                  name="voucher_photo"
                  onChange={handleChange}
                  className="mt-1 block w-full text-sm"
                  required
                />
              </div>
              <div>
                <label htmlFor="photo" className="block text-sm font-medium text-gray-900">फोटो:</label>
                <input
                  type="file"
                  id="photo"
                  name="applicant_photo"
                  onChange={handleChange}
                  className="mt-1 block w-full text-sm"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-indigo-600 px-4 py-2 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:ring-indigo-600"
            >
              Submit Application
            </button>
          </form>
          {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
          {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
        </div>
      ) : (
        <div className="text-center p-6">
          <h2 className="text-2xl font-semibold text-gray-900">Thank You!</h2>
          <p className="text-gray-600 mt-2">Your membership application has been submitted successfully.</p>
        </div>
      )}
    </div>
  );
}
