import React, { useState } from 'react';
import pachhaiSamajTransparent from '../Static/pachhaiSamajTransparent.png';
import { Link } from 'react-router-dom';

const openFacebookGroup = () => {
  window.open('https://www.facebook.com/groups/1129277277910557', '_blank');
};

const Header = () => {
  const [isNavVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible(!isNavVisible);
  };

  // Function to close the navigation
  const closeNav = () => {
    setNavVisible(false);
  };

  return (
    <div id="top" className="flex flex-col items-center justify-center z-50 relative mb-[4%]">
      <nav className="bg-blue-500 fixed w-full top-0 z-50 shadow-xl">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-6 mt-0">
          <div className="flex flex-col md:flex-row h-24 items-center justify-center">
            <div className="flex items-center w-full md:w-auto">
              <div className="md:hidden flex items-center ml-2">
                <button 
                  onClick={toggleNav} 
                  className="text-white text-xl focus:outline-none"
                >
                  {isNavVisible ? '✕' : '☰'}
                </button>
              </div>
              <img className="h-12 md:h-16 mx-auto md:mx-0" src={pachhaiSamajTransparent} alt="Pachhai Samaj Logo" />
             
              <div className="ml-3 text-white text-center md:text-left md:hidden flex flex-col">
                {/* Mobile View */}
                <span className="block text-xs font-bold " >
                   रैनादेवी छहरा मिलन समाज काठमाडौं<br/> 
                   <span style={{ fontSize: '0.6rem' }}>Rainadevi Chhahara Kathmandu Friendship Society 

                   </span>

                </span>
                <span className="block text-xxs" style={{ fontSize: '0.4rem' }}>
                Budhanilkantha-2, Kathmandu
                </span>
                <span className="block text-xxs" style={{ fontSize: '0.3rem' }}>
                  जि.प्र.का. काठमाडौँ दर्ता नं: ६९/२०८०/0८१ &nbsp;&nbsp; 
                  स.क.प. आबद्वाता नं: ५५८७८&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                </span>
              </div>
              {/* Desktop View */}
              <div className="hidden md:block text-white text-center md:text-center md:ml-[10px]">
                <span className="block text-xs md:text-sm font-bold">
                   रैनादेवी छहरा मिलन समाज काठमाडौं<br/> Rainadevi Chhahara Kathmandu Friendship Society
                </span>
                <span className="block text-xxs md:text-xs">
                   Budhanilkantha-2, Kathmandu
                </span>
                <span className="block text-xxs text-white-500 md:text-xxs" style={{ fontSize: '0.5rem' }}>
                  जि.प्र.का. काठमाडौँ दर्ता नं: ६९ / २०८० / 0८१ &nbsp;&nbsp;&nbsp; 
                  समाज कल्याण परिषद आबद्वता नं: ५५८७८&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/><br/>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="bg-blue-500 fixed w-full z-50 top-20">
  <div className="bg-blue-900 sm:flex md:items-center sm:justify-center py-0 shadow-xl">
    <div className="hidden md:flex flex-row md:space-x-1">
      <Link to="/" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Home Page</Link>
      <Link to="/members" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Membership</Link>
      <Link to="/gallery" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Photo Gallery</Link>
      <Link to="/events" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Events and Notice</Link>
      <Link to="/reportPublication" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Report and Publications</Link>
      <Link to="/contact" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Contact Us</Link>
      <a href="https://www.facebook.com/groups/1129277277910557" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium mb-1" style={{ fontSize: '0.7rem' }}>Facebook</a>
    </div>
  </div>

  {/* Mobile Navigation Links */}
  {isNavVisible && (
    <div className="md:hidden bg-blue-500 text-white py-1 px-2 flex flex-col items-center space-y-1">
      <Link 
        to="/" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium"  
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Home Page
      </Link>
      <Link 
        to="/members" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium"  
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Membership
      </Link>
      <Link 
        to="/gallery" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium" 
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Photo Gallery
      </Link>
      <Link 
        to="/events" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium" 
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Events and Notice
      </Link>
      <Link 
        to="/reportPublication" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium" 
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Report and Publications
      </Link>
      <Link 
        to="/contact" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium" 
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Contact Us
      </Link>
      <a 
        href="https://www.facebook.com/groups/1129277277910557" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-2 py-1 text-xxs font-medium"
        style={{ fontSize: '0.8rem' }}
        onClick={closeNav} // Close nav on click
      >
        Facebook
      </a>
    </div>
  )}
</div>

    </div>
  );
};

export default Header;
