import React, { useEffect } from 'react';

const DownloadSitemap = () => {
  useEffect(() => {
    // Function to trigger file download
    const downloadFile = () => {
      window.location.href = '/sitemap.xml';
    };

    // Trigger download
    downloadFile();
  }, []);

  return (
    <div>
      <p>Your download should start automatically. If not, <a href="/sitemap.xml">click here to download the sitemap</a>.</p>
    </div>
  );
};

export default DownloadSitemap;
