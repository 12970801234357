import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import BASE_URL from './Webserver';

const LifeMembers = () => {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [membershipType, setMembershipType] = useState('life'); // Toggle between 'life' and 'general'
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/members/`);
        setMembers(response.data);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, []);

  const filteredMembers = members
    .filter(member => member.membership_type === membershipType)
    .filter(member => `${member.english_name}`.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.membership_number - b.membership_number);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const paginatedMembers = filteredMembers.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  return (
    <div className="mt-[32%] sm:mt-[13%] mb-[2%]">
      <div className="text-center sm:py-1 ">
        <div className="container px-4 lg:px-8 mx-auto">
          <div className="text-center mb-2">
            <button
              className="px-4 py-2 rounded-l-lg mb-[1%] text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/members'}
            >
              View Executive Team
            </button>
            <button
              className="px-4 py-2 rounded-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/advisors'}
            >
              View Advisors Team
            </button>
            <button
              className="px-4 py-2 rounded-r-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/departments'}
            >
              View Departments
            </button>
          </div>

          {/* Title and Toggle Switch in the Same Line */}
          <div className="flex flex-col sm:flex-row items-center justify-center mb-4 space-x-4">
            <h2 className="text-md font-bold text-red-900 mb-2 sm:mb-0">सदस्यहरु :</h2>
            <div className="flex space-x-2">
              <button
                className={`px-4 py-2 rounded-l-lg text-xs font-medium ${membershipType === 'life' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                onClick={() => setMembershipType('life')}
              >
                आजीवन
              </button>
              <button
                className={`px-4 py-2 rounded-r-lg text-xs font-medium ${membershipType === 'general' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
                onClick={() => setMembershipType('general')}
              >
                साधारण 
              </button>
              
              {/* Search Bar */}
              <div className="mb-2 max-w-xs mx-auto">
                <input
                  type="text"
                  placeholder="Search by name..."
                  className="w-full px-4 py-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Memb. No</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Photo</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Origin Address</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Existing Address</th>
                  <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedMembers.map(member => (
                  <tr key={member.id} className="transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">
                      {member.membership_number}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap">
                      <img
                        className="h-8 w-8 rounded-full object-cover transition-transform duration-300 hover:scale-150"
                        src={member.photo}
                        alt={member.first_name}
                      />
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">
                      {member.first_name} {member.middle_name ? member.middle_name + ' ' : ''}{member.last_name}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500">
                      {member.phone_number}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500">
                      {member.origin_address}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500">
                      {member.existing_address}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500">
                      {member.email}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="mt-4 flex justify-center">
            <ReactPaginate
              previousLabel={"< Previous"}
              nextLabel={"Next >"}
              pageCount={Math.ceil(filteredMembers.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName={"flex space-x-2"}
              pageClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
              activeClassName={"bg-blue-600 text-white"}
              pageLinkClassName={"text-gray-700"}
              previousLinkClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
              nextLinkClassName={"px-3 py-1 border border-gray-300 rounded-lg text-xs font-medium"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeMembers;
