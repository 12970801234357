import React, { useState } from 'react';
import AboutEnglish from './AboutEnglish'; // Import the English content component
import Map from '../Static/Map.jpg';

export default function Example() {
  // State to keep track of the selected language
  const [language, setLanguage] = useState('nepali');

  return (
    <div id="about" className="relative isolate overflow-hidden bg-white px-0 py-2 sm:py-2 lg:px-2">
      <div className="mx-auto max-w-7xl">
     {/* Language Toggle Switch */}
<div className="flex justify-center mb-0">
  <button
    className={`px-1 py-1 text-xs font-medium rounded-l-lg ${language === 'nepali' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
    onClick={() => setLanguage('nepali')}
  >
    नेपाली
  </button>
  <button
    className={`px-1 py-1 text-xs font-medium rounded-r-lg ${language === 'english' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'}`}
    onClick={() => setLanguage('english')}
  >
    English
  </button>
</div>

        {/* Conditionally render content based on selected language */}
        {language === 'nepali' ? (
          <div className="space-y-0 lg:space-y-0">
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900">रैनादेवी छहरा मिलन समाज काठमाण्डौं</h3>
            <p className="text-justify text-xs px-4 py-1 leading-relaxed">
              रैनादेवी छहरा मिलन समाज काठमाडौँ एक अविच्छिन्न उत्तराधिकारवाला, संगठित, स्वशासित, गैर-राजनीतिक, गैर-नाफामूलक, गैर-सरकारी, जनहितकारी सामाजिक सँस्था हो । जिल्ला प्रशासन कार्यालय, काठमाडौंबाट मिति २०८०/०६/१० मा संस्था दर्ता ऐन, २०३४ को दफा (४) बमोजिम विधान दर्ता गरी प्रमाण-पत्र नं. ६९/२०८०/०८१ दिईएको छ । साथै, समाज कल्याण परिषद् ललितपुरबाट मिति २०८०/०६/१७ मा समाज कल्याण ऐन, २०४९ को दफा १३ बमोजिम आवध्दता प्रमाण-पत्र नं. ५५८७८ प्रदान गरिएको छ ।<br />
              रैनादेवी छहरा गाउँपालिका र काठमाडौँ उपत्यका कार्यक्षेत्र रहेको यस सँस्थाको कार्यालय हाल काठमाडौँ जिल्लाको बुढानीलकण्ठ नगरपालिका वडा नं. २ चपलीमा रहेको छ ।
            </p>
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900 ">सदस्यता सम्बन्धि व्यवस्था</h3>
            <p className="text-justify text-xs px-4 py-1 leading-relaxed ">
              रैनादेवी छहरा मिलन समाज काठमाण्डौँको विधान २०८० को परिच्छेद-३ को दफा ६ बमोजिम देहाय बमोजिम ४ प्रकारका सदस्यहरु रहने व्यवस्था गरिएको छ;<br />
              <span className='block pl-6'>
                <strong> (क) साधारण सदस्य</strong> <br />
                विधानको दफा ७ बमोजिम योग्यता भएको व्यक्ति तोकिएको प्रक्रिया पुरा गरी आवेदन गरेमा संस्थाको साधारण सदस्य हुन सक्ने छ।  <br />
                <strong > (ख) संस्थापक सदस्य</strong><br />
                यो संस्था दर्ता हुँदाको बखतमा सदस्यता प्राप्त गरिसकेका व्यक्तिहरु संस्थापक सदस्य हुनेछन् । संस्थापक सदस्यहरुले साधारण वा आजिवन सदस्यता लिएर विधान बमोजिमको शुल्क बुझाउनु पर्नेछ । <br />
                <strong > (ग) आजीवन सदस्य</strong><br />
                विधानको दफा ७ बमोजिम योग्यता पुगेको ब्यक्ति विधानमा तोकिएको प्रक्रिया पूरा गरी आवेदन गरेमा तोके बमोजिमको शुल्क लिई आजीवन सदस्यता प्रदान गर्न सकिने छ । <br />
                <strong> (घ) मानार्थ सदस्य</strong><br />
                संस्थाको विकासका लागी विशेष सहयोग, योगदान  पु¥याएको समाजसेवी एवं प्रतिष्ठित व्यक्तिलाई साधारणसभाको निर्णयले संस्थाको मानार्थ सदस्यता प्रदान गर्न सकिने छ ।<br />
              </span>
            </p>
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900 ">लक्ष्य</h3>
            <p className="text-justify text-xs px-4 leading-relaxed">
              दिगो सामुदायिक एवं आर्थिक विकास, वातावरणीय सन्तुलन, स्थानीय सँस्कृतिको संरक्षणमा योगदान गर्ने ।
            </p>
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900 ">उद्देश्यहरु</h3>
            <p className="text-justify text-xs px-4 leading-relaxed">
              नेपालको संबिधान, प्रचलित ऐन कानूनको अधीनमा रही गर्ने गरी देहाए बमोजिमका उद्देश्यहरु रहेका छन् :
              <ul className="list-disc pl-6">
                <li className="ml-4">
                  रैनादेवी छहरा गाउँपालिका भित्रका ऐतिहासिक एवं पुरातात्विक महत्वका स्थल, गढी, मठ मन्दिर, गुम्वा आदि धार्मिक, सांस्कृतिक धरोहरको खोज, अध्ययन, अन्वेषण गर्ने ।
                </li>
                <li className="ml-4">
                  नागरिकको समृद्धिका लागि शिक्षा, स्वास्थ्य, कृषि, वनपैदावर, पर्यटन आदिको खोजमूलक अध्ययन, अनुसन्धान गरी स्थानीय, प्रदेश र संघीय सरकारलाई राय, सुझाव दिने ।
                </li>
                <li className="ml-4">
                  नागरिकको समृद्धिका लागि शिक्षा, स्वास्थ्य, कृषि, वनपैदावर, पर्यटन आदिको खोजमूलक अध्ययन, अनुसन्धान गरी स्थानीय, प्रदेश र संघीय सरकारलाई राय, सुझाव दिने ।
                </li>
                <li className="ml-4">
                  आफ्नो कार्यक्षेत्रका जेष्ठ नागरिक, विभिन्न विधामा ख्याती प्राप्त गरेका विद्वान, विधार्थी, व्यवसायीलाई सम्मान तथा प्रोत्साहन पुरस्कार प्रदान गर्ने गराउने ।
                </li>
                <li className="ml-4">
                  आफ्नो कार्य क्षेत्रका मानिस बिच सम्पर्क र सम्वन्ध बढाउदै पारस्परिक सम्वन्ध अझ गाढा, कसिलो, सुमधुर बनाउदै सामजिक सदभाव र समानताको संस्कृतिको विकास गर्ने ।
                </li>
                <li className="ml-4">
                  आफ्नो कार्य क्षेत्रका अन्य गैरसरकारी संघ, संस्थाहरुको सम्मान, सुमधुर सम्वन्ध कायम गर्ने तथा ती संस्थाको रचनात्मक एवं सिर्जनात्मक काममा समन्वय तथा सहकार्य गर्ने ।
                </li>
              </ul>
            </p>
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight py-2 text-gray-900">रणनीतिहरु</h3>
            <p className="text-justify text-xs px-4 leading-relaxed">
              संस्थाको उद्देश्य हासिल गर्नको लागि देहाय बमोजिमका रणनीतिहरु अवलम्बन गरिने छः <br /></p>
            <ul className="text-justify text-xs mb-4 leading-relaxed list-disc pl-6">
              <li className="ml-8">
                सहभागितामूलक राजनीतिक प्रकृया समृध्दि र विकासको लागि स्थानीय जनताको साथ र सहयोग
              </li>
              <li className="ml-8">
                सविकासको लागि प्राथमिकता र प्रोत्साहन
              </li>
              <li className="ml-8">
                मानवीय पुँजीको सदुपयोग
              </li>
              <li className="ml-8">
                प्राकृतिक श्रोतको सदुपयोग
              </li>
              <li className="ml-8">
                प्राकृतिक प्रकोपबाट बच्न सक्ने क्षमता वृध्दि, जोखिम न्यूनीकरण र जलवायु परिबर्तन अनुकुलन
              </li>
              <li className="ml-8">
                आप्रवासी जनशक्तिको योगदानमा समन्वय
              </li>
            </ul>
            <h3 className="ml-4 text-red-900 text-sm font-bold tracking-tight text-gray-900">मूख्य कार्यहरु</h3>
            <p className="text-justify text-xs px-4 leading-relaxed">
              संस्थाबाट प्राथमिकता दिई देहायका कार्यहरु गरिने छः  <br />
              <ul role="list" className="list-disc pl-6 mt-0">
                <li className="ml-4 text-justify text-xs leading-relaxed">
                  सदस्यहरु बिच पारस्परिक सम्बन्धलाई सुदृढ बनाउँदै एक आपसमा भाईचारा, एकता तथा सामाजिक सदभाव र समानताको संस्कारको विकास गराउने ।
                </li>
                <li className="ml-4 text-justify text-xs leading-relaxed">
                  रैनादेवी छहरा गाउँपालिकाभित्रका ऐतिहासिक, पुरातात्विक स्थल, मठ, मन्दिर, सांस्कृतिक धरोहरको खोज र अन्वेषण गर्दै सृजनात्मक सिफारिस गर्ने ।
                </li>
                <li className="ml-4 text-justify text-xs leading-relaxed">
                  गाउँपालिकाको आर्थिक र सामाजिक रुपान्तरण, सांस्कृतिक पहिचानको संरक्षण र प्रवर्द्धनमा योगदान गर्ने ।
                </li>
                <li className="ml-4 text-justify text-xs leading-relaxed">
                  संस्थाको उद्देश्य पूरा गर्नका लागि आवश्यक भएका कार्यक्रमहरु योजना बनाई कार्यान्वयन गर्ने, सम्बन्धित निकायबाट अनुमति प्राप्त गरी कार्यान्वयन गर्ने ।
                </li>
              </ul>
            </p>
            <h3 className="ml-4 text-red-900 text-sm pt-2 font-bold tracking-tight text-gray-900">रैनादेवी छहरा गाउँपालिकाको संक्षिप्त परिचय</h3>
            <p className="text-justify pt-1 text-xs px-4 leading-relaxed">
              रैनादेवी छहरा गाउँपालिका प्याल्पा जिल्लाको पश्चिमी भागमा रहेको छ । यस गाउँपालिकामा पुर्वमा सिद्धेश्वर र तिनाउ गाउँपालिका, पश्चिममा अर्गाखाँची जिल्ला, उत्तरमा गुल्मी जिल्ला र दक्षिणमा रुपन्देही जिल्ला सिमा रहेको छ । यो गाउँपालिकामा पूर्वको ८ वटा गाउँ विकास समिति - सिद्धेश्वर, सोमादी, भुवनपोखरी, छहरा, मुजुङ, जुथापौवा, बल्डेघडी, सत्यवती रहेका छन् । कुल क्षेत्रफल १७५.८८ वर्ग किलोमिटर र जनसंख्या २६,४६९ रहेको छ (आ.व. २०७८ को जनगणना) ।<br />
              <span className="block ml-8">
              पूर्वः रिब्दीकोट र तिनाउ गा.पा.<br />
              पश्चिमः अर्घाखाँची जिल्ला<br />
              उत्तरः गुल्मी जिल्ला<br />
              दक्षिणः रुपन्देही जिल्ला
            </span>
              
              <div className="flex justify-center">
                <img
                  className="w-full max-w-[40rem] rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10"
                  src={Map}
                  alt="Geographical Map of Rainadevi Chhara Rural Municipality"
                />
              </div><br />
              <center className="ml-[10px] text-red-900 text-1xl font-bold tracking-tight text-gray-900">रैनादेवी छहरा गाउँपालिकाको भौगोलिक नक्सा</center>
            </p>
          </div>
        ) : (
          <AboutEnglish />
        )}
      </div>
    </div>
  );
}
