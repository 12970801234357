import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BASE_URL from './Webserver';

const Executive = () => {
  const [executiveMembers, setExecutiveMembers] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${BASE_URL}api/members/`)
      .then(response => response.json())
      .then(data => {
        // Filter and sort the members who have 'executive' category position
        const filteredAndSortedMembers = data
          .filter(member => member.category_position === 'executive')
          .sort((a, b) => a.membership_number - b.membership_number);

        // Update state with filtered and sorted members
        setExecutiveMembers(filteredAndSortedMembers);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className='mb-[2%]'>
      <div className="text-center mt-[30%] sm:mt-[13%]  sm:py-1 ">
          <button
              className="px-3 py-2 rounded-l-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 mb-[1%] focus:ring-blue-500"
              onClick={() => window.location.href = '/advisors'}
            >
              View Advisors Team
            </button>
        
            <button
              className="px-4 py-2 rounded-r-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/departments'}
            >
              View Departments
            </button>
            <button
              className="px-4 py-2 rounded-lg text-xs font-medium bg-blue-200 text-gray-800 mx-2 hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => window.location.href = '/lifemembers'}
            >
              View All Members
            </button>
         




      </div>
      <div id="members" className="bg-white">
        <div className="mx-auto grid max-w-4xl gap-x-4 gap-y-4 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-1xl">
            <h2 className=" ml-5px font-bold tracking-tight text-red-900 text-md">कार्यसमिति: </h2>
          </div>
          <ul role="list" className="grid gap-x-0 gap-y-1 sm:grid-cols-3 sm:gap-y-1 xl:col-span-2">
            {executiveMembers.map(member => (
              <li key={member.id}>
                <div className="flex items-center gap-x-4">
                  <img className="h-16 w-16 rounded-full" src={member.photo} alt={`${member.first_name} ${member.last_name}`} />
                  <div>
                    <h3 className="text-base text-xs font-semibold leading-4 tracking-tight text-gray-900">
                      {`${member.first_name} ${member.middle_name ? member.middle_name + ' ' : ''}${member.last_name}`}
                    </h3>
                    <p className="text-xs leading-4 text-indigo-600">{member.title}</p>
                    <p className="text-xs leading-4 text-indigo-600">{member.phone_number}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Executive;
