// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.about-container.visible {
  opacity: 1;
}

/* Example.css */

/* styles.css */

/* Custom scrollbar styling */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}


/* Optional: Adjust the modal content if needed */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,UAAU;EACV,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA,gBAAgB;;AAEhB,eAAe;;AAEf,6BAA6B;AAC7B;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;;AAGA,iDAAiD","sourcesContent":[".about-container {\n  transition: opacity 0.5s ease-in-out;\n  opacity: 0;\n  position: absolute;\n  width: 100%;\n}\n\n.about-container.visible {\n  opacity: 1;\n}\n\n/* Example.css */\n\n/* styles.css */\n\n/* Custom scrollbar styling */\n.scrollbar-thin::-webkit-scrollbar {\n  width: 6px;\n}\n\n.scrollbar-thin::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.3);\n  border-radius: 8px;\n}\n\n.scrollbar-thin::-webkit-scrollbar-track {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n\n/* Optional: Adjust the modal content if needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
