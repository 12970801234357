import React, { useState, useRef, useEffect } from 'react';
import About from './About';
import '../../App.css'; // Import the custom CSS for scrollbar styling

export default function Example() {
  const [showAbout, setShowAbout] = useState(false);
  const aboutRef = useRef(null);
  const modalRef = useRef(null);

  // Function to toggle modal visibility
  const handleAboutClick = () => {
    setShowAbout(prevState => !prevState);
    if (!showAbout && aboutRef.current) {
      aboutRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      window.scrollBy(0, 100);
    }
  };

  // Function to close modal
  const closeModal = () => {
    setShowAbout(false);
  };

  // Function to handle outside click
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  // Add event listener for outside clicks when modal is open
  useEffect(() => {
    if (showAbout) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showAbout]);

  return (
    <div className="relative bg-gray-50 overflow-hidden">
      {/* Background Gradient */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full -z-10 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
        />
      </div>

      <div className="relative max-w-4xl mx-auto py-1 px-2 lg:px-8 mt-20">
        <div className="mx-auto max-w-३xl py-0 lg:py-0">
          <div className="text-center">
            <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-xl mx-auto transition-transform transform hover:scale-105 duration-300 ease-in-out border border-gray-200">
              <h1 className="font-bold tracking-tight text-red-900 text-md">
                <marquee>रैनादेवी छहरा मिलन समाज काठमाडौंमा स्वागत छ !</marquee>
              </h1>

              <p className="mt-1 text-base font-bold text-xs leading-7 text-red-900 text-justify" >
              “जननी जन्मभूमिश्च स्वर्गादपि गरीयसी” भन्ने आर्दश श्लोकबाट अभिप्रेरित भई देशको मुटु मानिने राजधानी सहर काठमाण्डौं उपत्यकामा 
              विभिन्न पेशा, व्यवसाय गरी बसोबास गर्ने हामीहरू जन्मेहुर्केको ठाउँ रैनादेवी छहरा गाँउपालिका (पाल्पा), का कुनाकन्दरा, भिर, पाखापखेरा, खेतबारी, खोलाखोल्सी, रनबन, उकालीओराली, देउराली, भञ्जाङ्ग, चौतारी आदिले सजिएका मनोरम गाउँबस्तीहरुको
               सम्झना हाम्रो आँखाको नानीमा ताजै छ ।
               मुटु जन्मथलोको अगाध स्नेहले भरिएको छ । नसानसामा माटोको मायाँ, अपनत्व र देशभक्ति  सञ्चार भएको छ । 
               हामी आफ्नो जन्मभूमि प्रति गर्व गर्दै यथासक्यरुपमा दीगो सामुदायिक तथा आर्थिक विकास, वातावरणीय सन्तुलन, सामाजिक सद्भाव, 
               सहयोग, समन्वय, सहकार्य र सदाचार पध्दतिको अभिवृध्दि गर्न एकताबध्द भई होस्टेमा हैँसे गर्ने प्रण गर्दै रैनादेवी छहरा मिलन समाज
                काठमाडौँ (Rainadevi Chhahara Kathmandu Friendship Society) नामक संस्थामा आवध्द भै सामाजिक सेवामा सर्मपित हुने 
                सहर्ष घोषणा गर्दछौ ।              </p>
              <div className="mt-2 flex items-center justify-center gap-x-2">
                <button
                  onClick={handleAboutClick}
                  className="rounded-md bg-indigo-600 px-2 py-1 text-base font-semibold text-white shadow-lg hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors duration-300 text-xs ease-in-out"
                >
                  हाम्रो बारेमा
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for About component */}
      {showAbout && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div
            ref={modalRef}
            className="relative bg-white p-4 max-w-2xl w-full max-h-[90vh] overflow-y-auto rounded-lg shadow-xl scrollbar-thin"
          >
            <button
  className="absolute top-4 right-4 text-gray-800 text-2xl z-50"
  onClick={closeModal}
>
  &times;
</button>
            <About />
          </div>
        </div>
      )}
    </div>
  );
}
